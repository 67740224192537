<template>
  <div class="settings-container">
    <div v-if="userProfile" class="settings profile">
      <Typography class="pc-title-48 desktop-nav-only settings--header">Profile</Typography>
      <Avatar
        :size="208"
        :image="displayedAvatar"
        :seed="userProfile.nearId || null"
        class="user-avatar"
      >
        <input
          type="file"
          accept=".jpg,.jpeg,.png,.gif,.svg,.webp"
          class="avatar-selection"
          ref="avatar-selection"
          @change="onFileChange"
        >
      </Avatar>

      <div class="username-editor" :class="{ editing: editingName }">
        <input
          class="username"
          v-model="newUserName"
          type="text"
          ref="changeUsername"
          @focus="showInput"
          :class="{ editing: editingName }"
        >
        <span>
          <v-icon>mdi-pencil</v-icon>
        </span>
      </div>
      <button
        v-if="editingName || editingImage"
        class="standard inverted save"
        @click="saveEdits"
      >
        Save
      </button>
      <div class="profile--email">
        <Typography variant="pc-sub-16 mobile-sub-14">{{ currentUser.email }}</Typography>
        <span><img class="icon-12" src="../assets/icons/lock.svg" /></span>
      </div>

      <div class="account-info-container">
        <div class="token-count-desktop">
          <div class="token-count-desktop--item account">
            <img class="icon-21" src="../assets/icons/wallet.svg" />
            <span class="near-id">{{ formatNearId(userProfile.nearId) }}</span>
            <v-icon
              class="copy-button"
              @click="copyAddress"
              :size="14"
            >
              mdi-content-copy
            </v-icon>
          </div>
          <div class="token-count-desktop--item balance">
            <CryptoFiat :crypto="profileBalance" class="balance" />
          </div>
        </div>

        <div class="balance-mobile">
          <div>BALANCE</div>
          <CryptoFiat :crypto="profileBalance" class="balance" />
        </div>

        <div class="buttons">
          <button class="button" @click="showSeedPhrase">
            <img class="icon-21" src="../assets/icons/lock.svg" />
            <p>Back-Up</p>
          </button>
          <MoonPayTopUpButton />
          <button class="button" disabled>
            <v-icon :size="21">mdi-arrow-top-right</v-icon>
            <p>Send</p>
          </button>
        </div>
        <!-- <div class="buttons buy-sell">
          <button class="button" @click="showMoonPayModal('buy')">
            <p>Top-Up With</p>
            <img src="../assets/icons/moon-pay.svg" />
          </button>
          <button class="button" @click="showMoonPayModal('sell')">
            <p>Sell With</p>
            <img src="../assets/icons/moon-pay.svg" />
          </button>
        </div> -->
      </div>
      <LoaderCover v-if="saving" transparentFull center />
    </div>
    <SeedPhraseModal
      v-if="showingSeedPhraseModal"
      :seedPhrase="seedPhrase"
      @close="hideSeedPhrase"
    />
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex'
import SeedPhraseModal from '@/components/modals/SeedPhraseModal'
export default {
  components: {
    SeedPhraseModal
  },
  created () {
    this.newUserName = this.userProfile.name
    if (this.$route.query.modal === 'addFunds') this.addFunds()
    if (this.$route.query.modal === 'backup') this.showSeedPhrase()
  },
  data () {
    return {
      saving: false,
      newUserName: '',
      usernameWidth: 0,
      file: {
        rawData: null,
        fileData: null
      },
      seedPhrase: null,
      showingSeedPhraseModal: false
    }
  },
  computed: {
    ...mapGetters([
      'userProfile',
      'currentUser',
      'externalAccount',
      'profileBalance',
      'profileBalanceFormatted'
    ]),
    publicPath () {
      return process.env.BASE_URL
    },
    displayedAvatar () {
      if (this.editingImage) return this.file.rawData
      if (this.userProfile.customImage) return this.dynamicLink(this.userProfile.image, 'ar_1.0,c_fill,w_260')
      if (this.userProfile) return this.userProfile.image
      return null
    },
    editingName () {
      return this.newUserName !== this.userProfile.name
    },
    editingImage () {
      return this.file.rawData !== null
    },
    emailFormatted () {
      return this.currentUser.email.replace('@', '%40')
    }
  },
  methods: {
    ...mapMutations([
      'setGlobalState'
    ]),
    ...mapActions([
      'connectWallet',
      'updateUserProfile',
      'generateNearSeed'
    ]),
    updateUsername () {
      // Replace consecutive spaces with single space.
      this.newUserName = this.newUserName.replace(/\s\s+/g, ' ')
      setTimeout(() => {
        this.usernameWidth = this.$refs.measure.offsetWidth
      }, 100)
    },
    formatNearId (nearId) {
      if (!nearId) return ''
      if (nearId.length < 30) return nearId
      return `${nearId.substring(0, 27)}...`
    },
    async saveEdits () {
      this.saving = true
      console.log('Saving changes.')
      if (this.editingName) {
        try {
          await this.updateUserProfile({
            name: this.newUserName
          })
        } catch (err) {
          console.log(err)
          this.addNotification('Error saving edits.', 'error')
        }
      }
      if (this.editingImage) {
        try {
          const res = await this.fbCall('cloudinary-uploadUserProfileImage', {
            user: this.userProfile,
            imageData: this.file.rawData
          })
          if (res.data) {
            this.addNotification(res.data, 'success', true)
          } else {
            this.addNotification('Error updating user profile image.', 'error')
          }
        } catch (err) {
          console.log(err)
          this.addNotification('Error updating user profile image.', 'error')
        }
        this.file.rawData = null
      }
      this.saving = false
    },
    onFileChange (e) {
      console.log('User uploaded new avatar.')
      const files = e.target.files || e.dataTransfer.files
      if (!files.length) return

      const reader = new FileReader()
      reader.onload = () => {
        this.file.rawData = reader.result // Raw image data
      }
      reader.readAsDataURL(files[0])
      this.file.fileData = files[0]
    },

    copyAddress () {
      navigator.clipboard.writeText(this.userProfile.nearId)
      this.addNotification('Wallet address copied to clipboard.', 'info')
    },

    async addFunds () {
      // this.$router.push({
      //   path: 'settings',
      //   query: { modal: 'addFunds' }
      // })
      let connected
      if (!this.externalAccount) {
        connected = await this.connectWallet()
      } else {
        connected = true
      }

      if (connected) {
        this.setGlobalState({
          target: 'showingAddFunds',
          val: true
        })
      }
    },

    async handleSend () {
    },

    async showSeedPhrase () {
      this.showingSeedPhraseModal = true
      this.seedPhrase = await this.generateNearSeed()
    },
    hideSeedPhrase () {
      this.showingSeedPhraseModal = false
      this.seedPhrase = null
    },

    showInput () {
      this.$refs.changeUsername.scrollIntoView({ behavior: 'smooth' })
    }
  }
}
</script>

<style lang="scss" scoped>
.settings-container {
  padding-bottom: $space-l;
}
.settings {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0 auto;

  &--header {
    margin: 0 30px 30px;
    @include mobile {
      display: none;
    }
  }

  .avatar {
    margin: 0 auto $space-m;

    @include mobile {
      width: 160px !important;
      height: 160px !important;
    }
    .avatar-selection {
      cursor: pointer;
      width: 100%;
      height: 100%;
      opacity: 0;
    }
  }

  .connect {
    cursor: pointer;
  }
  .wallet-info {
    max-width: 500px;
    margin: $space-l auto;
  }
  .data-table {
    margin-bottom: $space-m;
    tr {
      td:first-child {
        width: 140px;
      }
    }
  }
  .wallet-actions {
    text-align: left;
    button {
      margin-right: $space-s;
    }
  }
  .save {
    margin: $space-l 0;
  }
}

.username-editor {
  position: relative;
  &:hover, &.editing {
    .v-icon {
      display: inline-block;
    }
  }
  input[type="text"] {
    all: unset;
    text-align: center;
    max-width: 400px;
    @include title(24px, 120%);
  }
  .measurement-copy {
    pointer-events: none;
    opacity: 0;
    text-transform: uppercase;
    font-size: 18px;
    white-space: nowrap;
  }
  span {
    position: absolute;
  }

  .v-icon {
    display: none;
    font-size: 18px;
    transform: translateX(50%);
  }
}

.profile {
  display: flex;
  min-height: 60vh;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #666666;

  &--header {
    margin: 30px;
    @include mobile {
      display: none;
    }
  }

  &--email {
    margin: 10px;
    display: flex;

    & > *:first-child {
      margin-right: 5px;
    }
  }
}

.icon-12 {
  vertical-align: middle;
  width: 12px;
  height: 12px;
  filter: invert(43%) sepia(0%) saturate(1139%) hue-rotate(223deg)
    brightness(87%) contrast(80%);
}

.icon-21 {
  width: 21px;
}
.account-info-container {
  width: 100%;
  max-width: 560px;
}
.token-count-desktop {
  @include body-text(16px);
  @include mobile {
    min-width: auto;
  }

  width: 100%;
  display: flex;
  border-bottom: 1px solid #ffffff;
  justify-content: space-between;
  margin-top: 30px;
  margin-bottom: 20px;
  padding-bottom: $space-xs;

  &--item {
    color: #ffffff;
    display: flex;
    justify-content: left;

    @include mobile {
      width: 100%;
    }

    &.balance {
      white-space: nowrap;
      justify-content: right;
      @include mobile {
        display: none;
      }
    }

    .copy-button {
      width: 24px;
    }

    .near-id {
      max-width: 70%;
      margin: 0 $space-s;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;

      @include mobile {
        max-width: 90%;
      }
    }
  }
}

.balance-mobile {
  @include desktop {
    display: none;
  }
  @include body-text(16px);

  display: flex;
  justify-content: space-between;
  color: #ffffff;
  border-bottom: 1px solid #ffffff;
  padding-bottom: $space-xs;
}

.buttons {
  @include mobile {
    margin: 50px;
  }

  @include desktop {
    display: flex;

    & > * {
      margin: 5px;
    }

    & > *:first-child {
      margin-left: 0;
    }

    & > *:last-child {
      margin-right: 0;
    }

    & > *:nth-child(2) {
      margin-left: auto;
    }

    &.buy-sell {
      button {
        flex: 1;
        flex-wrap: nowrap;
        justify-content: center;
      }
    }
  }
}

.button {
  border: 1px solid #ffffff;
  border-radius: 4px;
  padding: 12px 19px;
  display: flex;

  font-family: Untitled Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;

  & > *:first-child {
    margin-right: 5px;
  }

  @include mobile {
    width: 100%;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    padding: 10px;
    margin: 8px 0;
  }
}

.icon-send {
  width: 16px;
  transform: rotate(140deg);
}

</style>
