<template>
  <Modal
    class="seed-phrase-modal"
    title="Recovery Phrase"
    :subTitle="displayedSubtitle"
    compact
    :onConfirm="confirmAction"
    :confirmLabel="!acknowledged ? 'View' : ''"
    :cancelLabel="!acknowledged ? 'Cancel' : 'Close'"
    @close="$emit('close')"
  >
    <LoaderCover v-if="!seedPhraseArray" invert center />
    <p v-else-if="!acknowledged" class="message">
      You are about to display the seed phrase that can be used to access and recover your wallet. Do not share this phrase with anyone, and keep a copy in a safe location offline.
    </p>
    <div class="phrase-container" v-else>
      <ol>
        <li v-for="(word, index) in seedPhraseArray" :key="`word-${index}`">
          {{ word }}
        </li>
      </ol>

      <p class="wallet-link">
        <a :href="walletAddress" target="_blank" @click="$emit('close')">
          View my wallet<v-icon>mdi-open-in-new</v-icon>
        </a>
      </p>
    </div>
  </Modal>
</template>

<script>
export default {
  name: 'SeedPhraseModal',
  props: {
    seedPhrase: {
      type: String,
      default: null
    }
  },
  data () {
    return {
      acknowledged: false
    }
  },
  computed: {
    seedPhraseArray () {
      return this.seedPhrase ? this.seedPhrase.split(' ') : null
    },
    walletAddress () {
      const walletRoot = process.env.NODE_ENV === 'production' ? 'wallet.near.org' : 'near-wallet.onrender.com'
      return `https://${walletRoot}/auto-import-seed-phrase#${this.seedPhraseArray.join('%20')}`
    },
    displayedSubtitle () {
      return this.acknowledged ? 'Do not share' : 'Warning!'
    }
  },
  methods: {
    confirmAction () {
      this.acknowledged = true
    }
  }
}
</script>

<style lang="scss" scoped>
.phrase-container {
  text-align: left;
  ol {
    padding: 0 20px;
    columns: 2;
    -webkit-columns: 2;
    -moz-columns: 2;
    list-style: decimal-leading-zero inside;
  }
}
.wallet-link {
  margin-top: $space-m;
  padding: 0 20px;
  .v-icon {
    display: inline-block;
    margin: 0 $space-xs;
    color: inherit;
    font-size: inherit;
    text-decoration: none;
  }
}
</style>
